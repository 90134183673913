import { useQuery } from "@tanstack/react-query"
import { useEffect } from "react"
import { create } from "zustand"

import { trackAnalyticEvent } from "./analytics"
import { createJoonAPIClient } from "./joon-api"
import { QUERY_KEYS } from "../constants"
import { useUserQuery } from "../networking/queries"

interface DBExperiment {
  group_id: number
  experiment: string
  variant: string
}

interface OnboardingExperiment {
  name: string
  numVariants: number
}

export const cohorts = {
  control: "control",
  variantA: "variant-a",
  variantB: "variant-b",
  variantC: "variant-c",
}

export const experimentNames = {
  webParentVirtualCoachOnboarding: "web-parent-virtual-coach-onboarding",
}

export const getRandomCohort = (numCohorts: number) => {
  const cohortArray = Object.values(cohorts).slice(0, numCohorts)
  const randomIndex = Math.floor(Math.random() * cohortArray.length)
  return cohortArray[randomIndex]
}

export const onboardExperiments: OnboardingExperiment[] = []

type ExperimentName = (typeof onboardExperiments)[number]["name"]
type ExperimentCohort = (typeof cohorts)[keyof typeof cohorts]

interface OnboardExperimentStore {
  onboardExperiments: { [key: ExperimentName]: ExperimentCohort | null }
  setExperiment: (experiment: ExperimentName, value: ExperimentCohort) => void
}

export const useOnboardExperimentStore = create<OnboardExperimentStore>(
  (set) => ({
    onboardExperiments: Object.fromEntries(
      onboardExperiments.map((experiment) => [
        experiment.name,
        localStorage.getItem(experiment.name),
      ])
    ),
    setExperiment: (experiment, value) => {
      set((state) => ({
        onboardExperiments: {
          ...state.onboardExperiments,
          [experiment]: value,
        },
      }))
      localStorage.setItem(experiment, value)
    },
  })
)

export const useOnboardingExperiments = () => {
  const { setExperiment } = useOnboardExperimentStore()

  useEffect(() => {
    onboardExperiments.forEach((experiment) => {
      const experimentVariant = localStorage.getItem(experiment.name)
      if (!experimentVariant) {
        const variant = getRandomCohort(experiment.numVariants)

        setExperiment(experiment.name, variant)
        trackAnalyticEvent(`experiment_${experiment.name}`, { cohort: variant })
        localStorage.setItem(experiment.name, variant)
      } else {
        trackAnalyticEvent(`experiment_${experiment.name}`, {
          cohort: experimentVariant,
        })
        setExperiment(experiment.name, experimentVariant)
      }
    })
  }, [setExperiment])
}

const getExperimentCohorts = async () => {
  const API = createJoonAPIClient()
  try {
    const result = await API.get("api/experiment-cohorts/")
    const experiments: DBExperiment[] = result.data.results
    const experimentMap = {} as Record<string, string>
    experiments.forEach((experiment) => {
      const experimentName = `experiment_${experiment.experiment}`
      trackAnalyticEvent(experimentName, { cohort: experiment.variant })
      experimentMap[experiment.experiment] = experiment.variant
    })
    return experimentMap
  } catch (error) {
    console.error(error)
  }
}

export const useExperimentsQuery = () => {
  const { user } = useUserQuery()
  return useQuery({
    queryKey: [QUERY_KEYS.EXPERIMENTS],
    queryFn: getExperimentCohorts,
    enabled: !!user,
  })
}

export const useGetExperimentCohort = (experiment: string) => {
  const { data: dbExperiments, isLoading } = useExperimentsQuery()
  const { onboardExperiments } = useOnboardExperimentStore()

  // If is onboarding experiment, return that variant from zustand store
  const isOnboardingExperiment = !!onboardExperiments[experiment]
  if (isOnboardingExperiment) return onboardExperiments[experiment]

  // If is backend experiment, return that variant from query cache
  const isBackendExperiment = dbExperiments && !!dbExperiments[experiment]
  if (isBackendExperiment) return dbExperiments[experiment]

  // If it is no longer loading and the experiment is in neither, return control
  // Fallback case for if experiment is removed from backend
  if (!isLoading) return cohorts.control

  // return null if loading experiment still
  return null
}
