import {
  Button,
  CalendarCheckIcon,
  ChatIcon,
  FlexBox,
  GearIcon,
  HandHoldingHeartIcon,
  HeartIcon,
  InfoSolidIcon,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { isChrome, isMobile, isAndroid } from "react-device-detect"

import ListCard from "./ListCard"
import { useCoachingStore } from "./useCoachingStore"
import { useAddToHomescreenModal } from "../../components/modals/AddToHomescreenModal"
import { useIOSModal } from "../../components/modals/IOSModal"
import { CoachingStep } from "../../constants"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import {
  cohorts,
  experimentNames,
  useGetExperimentCohort,
} from "../../util/experiments"
import { useRouter } from "../../util/router"

const BasedOnResponsesPage = () => {
  const { setStep } = useCoachingStore()
  const router = useRouter()
  const { onOpen: openIOSModal } = useIOSModal()
  const { onOpen: openAddToHomescreenModal } = useAddToHomescreenModal()

  const isOnMobileChrome = isChrome && isMobile && isAndroid

  const isCoaching =
    useGetExperimentCohort(experimentNames.webParentVirtualCoachOnboarding) ===
    cohorts.variantA

  if (isCoaching === null) return <></>
  return (
    <FlexBox
      justify="flex-start"
      align="center"
      direction="column"
      fullWidth
      gap={SPACING.space4}
      style={{ minHeight: "70vh", padding: `${SPACING.space2} 0 0` }}
    >
      <FlexBox
        justify="flex-start"
        align="center"
        direction="column"
        fullWidth
        gap={SPACING.space2}
      >
        <Typography variant="bodySmall" color={JoonUIColor.text.secondary}>
          Based on your responses...
        </Typography>
        <Typography variant="h2" color={JoonUIColor.text.primary}>
          You may benefit from <br />
          {`Virtual Parent ${isCoaching ? "Coaching" : "Training"}`}
        </Typography>
      </FlexBox>

      <FlexBox
        direction="column"
        align="center"
        justify="center"
        gap={SPACING.space3}
        style={{ width: isCoaching ? "100%" : "82%" }}
      >
        <Typography variant="bodyBold" color={JoonUIColor.text.primary}>
          Get personalized strategies <br />
          and 1-on-1 support to:
        </Typography>
        <div>
          <ListCard
            icon={InfoSolidIcon}
            text={
              isCoaching
                ? "Overcome the most common challenges parents encounter"
                : "Manage challenging child behaviors"
            }
            iconColor={JoonColorExpanded.yellow400}
            backgroundColor={JoonColorExpanded.yellow100}
            gap={isCoaching ? SPACING.space3 : SPACING.space2}
          />
          <ListCard
            icon={HeartIcon}
            text={
              isCoaching
                ? "Learn techniques to feel less stressed as a parent"
                : "Learn stress management techniques"
            }
            iconColor={JoonColorExpanded.yellow400}
            backgroundColor={JoonColorExpanded.yellow100}
            gap={isCoaching ? SPACING.space3 : SPACING.space2}
          />
          <ListCard
            icon={isCoaching ? HandHoldingHeartIcon : GearIcon}
            text={
              isCoaching
                ? "Discover strategies for bonding with your child"
                : "Create systems to improve behavior"
            }
            iconColor={JoonColorExpanded.yellow400}
            backgroundColor={JoonColorExpanded.yellow100}
            gap={isCoaching ? SPACING.space3 : SPACING.space2}
          />
        </div>
      </FlexBox>

      <FlexBox
        direction="column"
        align="center"
        justify="center"
        gap={SPACING.space3}
        style={{ width: isCoaching ? "100%" : "90%" }}
      >
        <Typography variant="bodyBold" color={JoonUIColor.text.primary}>
          Our program is designed <br />
          for parents like you:
        </Typography>
        <div>
          <ListCard
            icon={CalendarCheckIcon}
            text={
              isCoaching
                ? "Regular, flexible, and affordable virtual sessions"
                : "Regular & flexible virtual appointments, covered by insurance"
            }
            iconColor={JoonUIColor.background.accent}
            backgroundColor={JoonUIColor.background.lightAccent}
            gap={SPACING.space3}
          />
          <ListCard
            icon={ChatIcon}
            text={`Unlimited messaging with your ${isCoaching ? "coach" : "clinician"}`}
            iconColor={JoonUIColor.background.accent}
            backgroundColor={JoonUIColor.background.lightAccent}
            gap={SPACING.space3}
          />
        </div>
      </FlexBox>

      <FlexBox
        direction="column"
        align="center"
        gap={SPACING.space3}
        style={{ width: "90%", marginBottom: "70px" }}
      >
        <Button
          fullWidth
          onClick={() => {
            trackAnalyticEvent(ANALYTIC_EVENTS.VIRTUAL_COACH_CONTINUE_CLICK, {
              step: CoachingStep.BASED_ON_RESPONSES,
            })
            setStep(CoachingStep.PROGRAM_DETAILS)
          }}
          text={`Learn About Virtual Parent ${isCoaching ? "Coaching" : "Training"}`}
        />
        <TextButton
          onClick={() => {
            trackAnalyticEvent(ANALYTIC_EVENTS.VIRTUAL_COACH_SKIP_CLICK, {
              step: CoachingStep.BASED_ON_RESPONSES,
            })
            router.push("/me")
            if (isOnMobileChrome) openAddToHomescreenModal()
            else openIOSModal()
          }}
        >
          <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
            No Thanks, Continue to Joon App
          </Typography>
        </TextButton>
      </FlexBox>
    </FlexBox>
  )
}

export default BasedOnResponsesPage
