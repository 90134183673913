import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  TherapistIcon,
  Typography,
} from "@joonapp/web-shared"

import AcademyActions from "./AcademyActions"

const AcademyPageHeader = () => {
  return (
    <FlexBox
      direction="column"
      gap={SPACING.space2}
      wrap={false}
      style={{
        background: JoonColorExpanded.purple600,
        width: "min(600px, 100%)",
        padding: SPACING.space4,
        borderRadius: `0 0 ${SPACING.space4} ${SPACING.space4}`,
      }}
    >
      <Typography variant="h2" color={JoonUIColor.text.inverted}>
        Joon Academy
      </Typography>
      <FlexBox direction="row" wrap={false} gap={SPACING.space4}>
        <FlexBox wrap={false} align="center" gap={SPACING.space1}>
          <TherapistIcon size={24} color={JoonColorExpanded.purple600} />
          <Typography variant="bodyBold" color={JoonUIColor.text.inverted}>
            Daily to-dos
          </Typography>
        </FlexBox>
      </FlexBox>
      <AcademyActions />
    </FlexBox>
  )
}

export default AcademyPageHeader
