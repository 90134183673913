import {
  Button,
  CalendarCheckIcon,
  FlexBox,
  InfoIcon,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { isAndroid, isChrome, isMobile } from "react-device-detect"

import ListCard from "./ListCard"
import { ProgramStepIcon } from "./ProgramStepIcon"
import { useAddToHomescreenModal } from "../../components/modals/AddToHomescreenModal"
import { useIOSModal } from "../../components/modals/IOSModal"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"

const ScheduledPage = () => {
  const router = useRouter()
  const { onOpen: openIOSModal } = useIOSModal()
  const { onOpen: openAddToHomescreenModal } = useAddToHomescreenModal()

  const isOnMobileChrome = isChrome && isMobile && isAndroid

  return (
    <FlexBox
      justify="flex-start"
      align="center"
      direction="column"
      fullWidth
      gap={SPACING.space4}
      style={{ minHeight: "70vh", paddingTop: "20px" }}
    >
      <FlexBox
        direction="column"
        align="center"
        justify="center"
        gap={SPACING.space2}
        style={{ margin: `${SPACING.space6} 0` }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: SPACING.space4,
            backgroundColor: JoonColorExpanded.viridian100,
            width: "62px",
            height: "62px",
            transform: "rotate(15deg)",
            paddingBottom: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transform: "rotate(-15deg)",
            }}
          >
            <CalendarCheckIcon size={48} color={JoonUIColor.semantic.success} />
          </div>
        </div>
        <Typography variant="h2" color={JoonUIColor.text.primary}>
          Care Navigator <br />
          call scheduled!
        </Typography>
      </FlexBox>

      {router.query.from === "academy" ? (
        <>
          <ListCard
            icon={InfoIcon}
            text="Before your call, make a note of anything you’d like to review with our Care Navigator!"
            backgroundColor={JoonUIColor.background.lightAccent}
            gap={SPACING.space2}
            iconColor={JoonUIColor.border.accent}
          />
          <Typography
            variant="bodySmall"
            color={JoonUIColor.text.primary}
            style={{ fontStyle: "italic" }}
          >
            If you have any questions beforehand, feel <br />
            free to email us at contact@joonapp.io
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="bodyBold" color={JoonUIColor.text.primary}>
            While we wait for your scheduled call, <br />
            let's start building a routine for your child!
          </Typography>

          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
              zIndex: 0,
            }}
          >
            <ListCard
              icon={ProgramStepIcon}
              text="Start by creating a few daily routine quests for your child"
              backgroundColor={JoonUIColor.background.lightAccent}
              gap={SPACING.space2}
              step={1}
            />
            <ListCard
              icon={ProgramStepIcon}
              text="Set up custom rewards in the rewards tab to help with child motivation and buy-in."
              backgroundColor={JoonUIColor.background.lightAccent}
              gap={SPACING.space2}
              step={2}
            />
            <ListCard
              icon={ProgramStepIcon}
              text="Make a note on anything you’d like to review with our Care Navigator."
              backgroundColor={JoonUIColor.background.lightAccent}
              gap={SPACING.space2}
              step={3}
            />
            <div
              style={{
                position: "absolute",
                height: "90%",
                left: "23px",
                backgroundColor: "transparent",
                borderLeft: `1px dashed ${JoonColorExpanded.indigo200}`,
                zIndex: -1,
              }}
            />
          </div>
        </>
      )}

      <Button
        fullWidth
        onClick={() => {
          router.push("/me")
          if (router.query.from === "academy") return
          trackAnalyticEvent(ANALYTIC_EVENTS.VIRTUAL_COACH_ONBOARDING_COMPLETE)
          if (isOnMobileChrome) openAddToHomescreenModal()
          else openIOSModal()
        }}
        text={router.query.from === "academy" ? "Done" : "Let's go!"}
      />
    </FlexBox>
  )
}

export default ScheduledPage
