import {
  Button,
  FlexBox,
  JoonUIColor,
  PhoneIcon,
  SPACING,
  TextButton,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"
import { isAndroid, isChrome, isMobile } from "react-device-detect"

import ListCard from "./ListCard"
import { useCoachingStore } from "./useCoachingStore"
import { CountryCode } from "../../components/modals/addPhoneNumberModal/AddPhoneNumberModal"
import { PhoneNumberReminder } from "../../components/modals/addPhoneNumberModal/PhoneNumberReminder"
import { useNotificationPreferencesMutation } from "../../components/modals/addPhoneNumberModal/useNotificationPreferencesMutation"
import { usePhoneNumberMutation } from "../../components/modals/addPhoneNumberModal/usePhoneNumberMutation"
import { useAddToHomescreenModal } from "../../components/modals/AddToHomescreenModal"
import { useIOSModal } from "../../components/modals/IOSModal"
import { CoachingStep } from "../../constants"
import { useUserQuery } from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import {
  cohorts,
  experimentNames,
  useGetExperimentCohort,
} from "../../util/experiments"
import { useRouter } from "../../util/router"

const PhoneNumberPage = () => {
  const router = useRouter()
  const { user } = useUserQuery()
  const {
    phoneNumber,
    setPhoneNumber,
    phoneNumberInput,
    setPhoneNumberInput,
    setStep,
  } = useCoachingStore()
  const { onOpen: openIOSModal } = useIOSModal()
  const { onOpen: openAddToHomescreenModal } = useAddToHomescreenModal()
  const phoneNumberMutation = usePhoneNumberMutation()
  const notificationPreferencesMutation = useNotificationPreferencesMutation()

  const isOnMobileChrome = isChrome && isMobile && isAndroid

  const isCoaching =
    useGetExperimentCohort(experimentNames.webParentVirtualCoachOnboarding) ===
    cohorts.variantA

  const isValidPhoneNumber = () => {
    const phoneRegex = /^\+1\d{10}$/
    return phoneRegex.test(phoneNumber)
  }

  const onChangePhoneNumber = (value: string) => {
    const cleanedValue = value.replace(/\D/g, "")
    const match = cleanedValue.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/)
    if (!match) return
    let formattedValue = match[1] ? `(${match[1]}` : ""
    if (match[2]) formattedValue += `) ${match[2]}`
    if (match[3]) formattedValue += `-${match[3]}`
    setPhoneNumber(`+1${cleanedValue}`)
    setPhoneNumberInput(formattedValue)
  }

  const onAddNumber = async () => {
    Promise.all([
      phoneNumberMutation.mutateAsync(phoneNumber),
      notificationPreferencesMutation.mutateAsync(true),
    ]).then(() =>
      trackAnalyticEvent(ANALYTIC_EVENTS.VIRTUAL_COACH_PHONE_NUMBER_SUBMIT)
    )
    trackAnalyticEvent(ANALYTIC_EVENTS.VIRTUAL_COACH_CONTINUE_CLICK, {
      step: CoachingStep.PHONE_NUMBER,
    })
    setStep(CoachingStep.CALENDLY)
  }

  const onSkip = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.VIRTUAL_COACH_SKIP_CLICK, {
      step: CoachingStep.PHONE_NUMBER,
    })
    router.push("/me")
    if (isOnMobileChrome) openAddToHomescreenModal()
    else openIOSModal()
  }

  useEffect(() => {
    if (user?.phone_number && user?.phone_number.length > 0) {
      setPhoneNumber(user?.phone_number)
      onChangePhoneNumber(user?.phone_number.substring(2))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FlexBox
      justify="flex-start"
      align="center"
      direction="column"
      fullWidth
      gap={SPACING.space4}
      style={{ minHeight: "70vh", paddingTop: "20px", position: "relative" }}
    >
      <Typography variant="h2" color={JoonUIColor.text.primary}>
        Schedule your free <br />
        15 minute initial call
      </Typography>
      <ListCard
        icon={PhoneIcon}
        text={`You will meet with our Care Navigator to discuss your ${isCoaching ? "coaching preferences (frequency & length) and pricing information ($60 per 30 minute session)" : "goals and preferences"}`}
        iconColor={JoonUIColor.icon.accent}
        backgroundColor={JoonUIColor.background.lightAccent}
        gap={SPACING.space2}
      />

      <Typography variant="h3" color={JoonUIColor.text.primary}>
        Add phone number
      </Typography>
      <Typography variant="bodySmall" color={JoonUIColor.text.primary}>
        This will allow us to send you notifications and <br />
        information about your upcoming appointment
      </Typography>

      <TextInput
        name="Phone Number"
        value={phoneNumberInput}
        onChange={(e) => onChangePhoneNumber(e.target.value)}
        maxLength={14}
        placeholder="(123) 456-7890"
        fullWidth
        Icon={CountryCode}
        style={{
          height: SPACING.space14,
          background: JoonUIColor.background.xlightGray,
          paddingLeft: SPACING.space5,
        }}
      />

      <Button
        fullWidth
        onClick={onAddNumber}
        text="Add number"
        disabled={!isValidPhoneNumber()}
        isLoading={
          phoneNumberMutation.isLoading ||
          notificationPreferencesMutation.isLoading
        }
      />
      <PhoneNumberReminder />
      <FlexBox
        direction="column"
        align="center"
        gap={SPACING.space3}
        style={{ marginBottom: "50px" }}
      >
        <TextButton onClick={onSkip}>
          <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
            Skip, Continue to Joon App
          </Typography>
        </TextButton>
      </FlexBox>
    </FlexBox>
  )
}

export default PhoneNumberPage
