import {
  FlexBox,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { InlineWidget, useCalendlyEventListener } from "react-calendly"
import { isAndroid, isChrome, isMobile } from "react-device-detect"

import { useCoachingStore } from "./useCoachingStore"
import { useAddToHomescreenModal } from "../../components/modals/AddToHomescreenModal"
import { useIOSModal } from "../../components/modals/IOSModal"
import { CoachingStep } from "../../constants"
import { useUserQuery } from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"

const CalendlyComponent: React.FC = () => {
  const router = useRouter()
  const { setStep } = useCoachingStore()
  const { user } = useUserQuery()

  useCalendlyEventListener({
    onDateAndTimeSelected: () => {
      if (router.query.from === "academy")
        trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_DATE_TIME_SELECTED)
      else trackAnalyticEvent(ANALYTIC_EVENTS.CALENDLY_DATE_TIME_SELECTED)
    },
    onEventScheduled: () => {
      if (router.query.from === "academy")
        trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_EVENT_SCHEDULED)
      else trackAnalyticEvent(ANALYTIC_EVENTS.CALENDLY_EVENT_SCHEDULED)
      setStep(CoachingStep.SCHEDULED)
    },
  })

  return (
    <div
      style={{
        width: "min(450px, 100%)",
        boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.08)",
      }}
    >
      <InlineWidget
        url="https://calendly.com/joonapp/joon-care-navigator-call"
        pageSettings={{
          hideEventTypeDetails: false,
          hideLandingPageDetails: false,
          primaryColor: JoonUIColor.text.primaryAccent,
          textColor: JoonUIColor.text.primary,
        }}
        prefill={{
          email: user?.email,
          name: `${user?.name}${user?.last_name ? ` ${user?.last_name}` : ""}`,
        }}
      />
    </div>
  )
}

const CalendlyPage = () => {
  const router = useRouter()
  const { onOpen: openIOSModal } = useIOSModal()
  const { onOpen: openAddToHomescreenModal } = useAddToHomescreenModal()

  const isOnMobileChrome = isChrome && isMobile && isAndroid

  return (
    <FlexBox
      justify="flex-start"
      align="center"
      direction="column"
      style={{
        minHeight: "70vh",
        width: "min(450px, 90vw)",
        paddingTop: "20px",
      }}
    >
      <Typography
        variant="h2"
        color={JoonUIColor.text.primary}
        style={{ marginBottom: SPACING.space4 }}
      >
        Schedule your free <br />
        15 minute initial call
      </Typography>

      <CalendlyComponent />

      <FlexBox
        direction="column"
        align="center"
        gap={SPACING.space3}
        style={{ marginTop: SPACING.space4, marginBottom: "50px" }}
      >
        <TextButton
          onClick={() => {
            router.push("/me")
            if (router.query.from === "academy") {
              trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_NEVERMIND_CLICK)
              return
            }
            trackAnalyticEvent(ANALYTIC_EVENTS.VIRTUAL_COACH_SKIP_CLICK, {
              step: CoachingStep.CALENDLY,
            })
            if (isOnMobileChrome) openAddToHomescreenModal()
            else openIOSModal()
          }}
        >
          <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
            {router.query.from === "academy"
              ? "Nevermind"
              : "Skip, Continue to Joon App"}
          </Typography>
        </TextButton>
      </FlexBox>
    </FlexBox>
  )
}

export default CalendlyPage
