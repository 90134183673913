import {
  CalendarCheckIcon,
  CheckIcon,
  FlexBox,
  HandshakeAngleIcon,
  JoonColorExpanded,
  JoonUIColor,
  LocationArrowIcon,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"

import ReadyButton from "./ReadyButton"
import { CoachingStep } from "../../../constants"
import useMobile from "../../../hooks/useMobile"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import {
  cohorts,
  experimentNames,
  useGetExperimentCohort,
} from "../../../util/experiments"
import { useRouter } from "../../../util/router"
import { useCoachingStore } from "../../coaching/useCoachingStore"

const CareMarketingPage = () => {
  const coachingVariant = useGetExperimentCohort(
    experimentNames.webParentVirtualCoachOnboarding
  )
  const isCoaching = coachingVariant === cohorts.variantA
  const isMobile = useMobile()
  const router = useRouter()
  const { setStep } = useCoachingStore()

  const onReadyButtonClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_BUTTON_CLICK)
    setStep(CoachingStep.CALENDLY)
    router.push("/coaching?from=academy")
  }

  useEffect(() => {
    if (router.pathname === "/academy")
      trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_TAB_VIEWED)
  }, [router.pathname])

  const mainHeaderText = () => {
    switch (coachingVariant) {
      case cohorts.variantA:
        return "Get a coach who gets you"
      case cohorts.variantB:
        return "Get a therapist who gets your family"
      case cohorts.variantC:
        return "Get a therapist, covered by insurance"
      default:
        return ""
    }
  }

  const howThisWorksText = () => {
    switch (coachingVariant) {
      case cohorts.variantA:
        return "Get matched with the best coach for your family"
      case cohorts.variantB:
        return "Get matched with the best behavioral therapist for your family"
      case cohorts.variantC:
        return "Get matched with the best behavioral therapist for your family, covered by your insurance"
      default:
        return ""
    }
  }

  const weeklySessionsText = () => {
    switch (coachingVariant) {
      case cohorts.variantA:
        return (
          <Typography
            variant="bodySmall"
            textAlign="left"
            style={{ width: "80%" }}
          >
            Weekly 45 min 1-on-1 sessions with your coach
          </Typography>
        )
      case cohorts.variantB:
        return (
          <Typography
            variant="bodySmall"
            textAlign="left"
            style={{ width: "80%" }}
          >
            Weekly 1-hour sessions with a therapist
          </Typography>
        )
      case cohorts.variantC:
        return (
          <Typography
            variant="bodySmall"
            textAlign="left"
            style={{ width: "80%" }}
          >
            Weekly 1-hour sessions with a therapist,{" "}
            <Typography variant="bodySmall" style={{ fontWeight: "800" }}>
              covered by insurance
            </Typography>
          </Typography>
        )
      default:
        return ""
    }
  }

  return (
    <FlexBox
      direction="column"
      align="center"
      wrap={false}
      style={{
        position: "relative",
        padding: SPACING.space0,
        paddingTop: SPACING.space9,
        width: "min(600px, 100%)",
      }}
      gap={SPACING.space9}
    >
      <FlexBox direction="column" align="center" gap={SPACING.space4}>
        <Typography variant="bodySmall" textAlign="center">
          Finally...
        </Typography>
        <Typography variant="h1" textAlign="center" style={{ width: "75%" }}>
          {mainHeaderText()}
        </Typography>
        <Typography
          variant="body"
          textAlign="center"
          style={{ width: "min(400px, 85%)" }}
        >
          {`Connect 1:1 with a ${isCoaching ? "coach" : "therapist"}, right here in the app and `}
          <Typography variant="bodyBold">
            get support like never before
          </Typography>
        </Typography>
        <img
          src={`/images/guidePhotos/${isCoaching ? "coach" : "care"}_main_headline.svg`}
          style={{ width: "min(380px, 80%)" }}
          alt="<3"
        />
      </FlexBox>

      <img src="/images/squiggle.svg" style={{ width: "70%" }} alt="~~~" />

      <Typography variant="h2" textAlign="center" style={{ width: "75%" }}>
        {`Why you should consider 
          ${isCoaching ? "a coach" : "behavioral therapy for you and your child"}`}
      </Typography>
      <FlexBox
        direction="column"
        align="center"
        gap={SPACING.space12}
        style={{ marginBottom: SPACING.space4 }}
      >
        <FlexBox direction="column" align="center">
          <Typography
            variant="bodyBold"
            textAlign="center"
            style={{ width: "min(400px, 85%)" }}
          >
            Get practical strategies for managing challenging behaviors
          </Typography>
          <img
            src={`/images/guidePhotos/${isCoaching ? "coach" : "care"}_practical_strategy.svg`}
            style={{ width: "min(400px, 80%)", paddingBottom: SPACING.space3 }}
            alt="Practical strategies"
          />
          <Typography
            variant="bodySmall"
            textAlign="center"
            style={{ width: "min(400px, 85%)" }}
          >
            {isCoaching
              ? "Message your coach anytime and retain continuity between daily challenges and active sessions."
              : "Stay connected with your family's therapist between sessions. Share daily challenges and get ongoing personalized support when you need it most."}
          </Typography>
        </FlexBox>
        <FlexBox direction="column" align="center">
          <Typography variant="bodyBold" textAlign="center">
            Improve your relationship with your child
          </Typography>
          <img
            src={`/images/guidePhotos/parent_task.svg`}
            style={{ width: "min(400px, 80%)", paddingTop: SPACING.space3 }}
            alt="Practical strategies"
          />
          <Typography
            variant="bodySmall"
            textAlign="center"
            style={{ width: "min(400px, 85%)" }}
          >
            {`Receive guided practice ${isCoaching ? "tasks" : "activities"} to continue improving your relationship with your child`}
          </Typography>
        </FlexBox>
        <FlexBox direction="column" align="center">
          <Typography
            variant="bodyBold"
            textAlign="center"
            style={{ width: "min(400px, 85%)" }}
          >
            {`Virtually meet your ${isCoaching ? "coach" : "family's therapist"}, anytime, anywhere`}
          </Typography>
          <img
            src={`/images/guidePhotos/zoom.svg`}
            style={{
              width: "min(400px, 80%)",
              paddingTop: SPACING.space3,
              paddingBottom: SPACING.space2,
            }}
            alt="Meet Your Coach"
          />
          <Typography
            variant="bodySmall"
            textAlign="center"
            style={{ width: "min(400px, 70%)" }}
          >
            All you need is a little space and an open mind to get started
          </Typography>
        </FlexBox>
        <FlexBox direction="column" align="center">
          <Typography variant="bodyBold" textAlign="center">
            On demand curated learning
          </Typography>
          <img
            src={`/images/guidePhotos/learning_modules.svg`}
            style={{
              width: "min(400px, 80%)",
            }}
            alt="Learning Modules"
          />
          <Typography
            variant="bodySmall"
            textAlign="center"
            style={{ width: "min(400px, 70%)" }}
          >
            {`Continue to learn while on the go with bite-sized modules specifically curated by ${isCoaching ? "your coach" : "a therapist"}`}
          </Typography>
        </FlexBox>
      </FlexBox>

      <FlexBox
        direction="column"
        align="center"
        wrap={false}
        style={{ width: "min(500px, 100%)" }}
      >
        <FlexBox
          direction="column"
          align="center"
          gap={SPACING.space10}
          style={{
            background: JoonColorExpanded.purple600,
            padding: SPACING.space4,
            paddingBottom: SPACING.space12,
            borderTopLeftRadius: SPACING.space4,
            borderTopRightRadius: SPACING.space4,
          }}
        >
          <Typography
            variant="h2"
            textAlign="center"
            color={JoonUIColor.text.inverted}
            style={{ marginTop: SPACING.space4 }}
          >
            How this works
          </Typography>
          <FlexBox direction="column" align="center" gap={SPACING.space2}>
            <HandshakeAngleIcon size={48} color={JoonColorExpanded.yellow400} />
            <Typography
              variant="bodyBold"
              textAlign="center"
              color={JoonUIColor.text.inverted}
              style={{ width: "min(400px, 70%)" }}
            >
              {howThisWorksText()}
            </Typography>
            <Typography
              variant="bodySmall"
              textAlign="center"
              color={JoonUIColor.text.inverted}
              style={{ width: "min(400px, 70%)" }}
            >
              {`Our Care Navigator will provide you with a curated list of best ${isCoaching ? "coaches" : "therapists"} that matches your family's needs`}
            </Typography>
          </FlexBox>
          <FlexBox direction="column" align="center" gap={SPACING.space2}>
            <CalendarCheckIcon size={48} color={JoonColorExpanded.yellow400} />
            <Typography
              variant="bodyBold"
              textAlign="center"
              color={JoonUIColor.text.inverted}
            >
              Always on your own time
            </Typography>
            <Typography
              variant="bodySmall"
              textAlign="center"
              color={JoonUIColor.text.inverted}
            >
              Schedule times that work for you
            </Typography>
          </FlexBox>
          <FlexBox direction="column" align="center" gap={SPACING.space2}>
            <LocationArrowIcon size={48} color={JoonColorExpanded.yellow400} />
            <Typography
              variant="bodyBold"
              textAlign="center"
              color={JoonUIColor.text.inverted}
              style={{ width: "min(400px, 70%)" }}
            >
              Let's set your goals
            </Typography>
            <Typography
              variant="bodySmall"
              textAlign="center"
              color={JoonUIColor.text.inverted}
              style={{ width: "min(400px, 70%)" }}
            >
              Establish parenting goals, set attainable targets, and make
              meaningful progress towards your parenting goals
            </Typography>
          </FlexBox>
        </FlexBox>

        <FlexBox
          direction="column"
          align="center"
          gap={SPACING.space6}
          wrap={false}
          style={{
            height: !isMobile ? "400px" : "470px",
            background: JoonColorExpanded.yellow400,
            paddingTop: SPACING.space12,
          }}
        >
          <Typography
            variant="h2"
            textAlign="center"
            color={JoonUIColor.text.primary}
            style={{ width: "75%" }}
          >
            {`What you'll get with your ${isCoaching ? "coaching" : "therapy"} plan`}
          </Typography>

          <FlexBox
            direction="column"
            justify="left"
            gap={SPACING.space4}
            wrap={false}
            style={{
              width: "auto",
              borderTopLeftRadius: SPACING.space4,
              borderTopRightRadius: SPACING.space4,
              height: "420px",
              padding: "24px",
              background: JoonColorExpanded.yellow100,
              margin: `0 ${SPACING.space6}`,
            }}
          >
            <FlexBox direction="row" gap={SPACING.space2} align="center">
              <CheckIcon size={28} color={JoonColorExpanded.viridian400} />
              {weeklySessionsText()}
            </FlexBox>
            <FlexBox direction="row" gap={SPACING.space2} align="center">
              <CheckIcon size={28} color={JoonColorExpanded.viridian400} />
              <Typography
                variant="bodySmall"
                textAlign="left"
                style={{ width: "80%" }}
              >
                Access to personalized resources and strategies for your family
              </Typography>
            </FlexBox>
            <FlexBox direction="row" gap={SPACING.space2} align="center">
              <CheckIcon size={28} color={JoonColorExpanded.viridian400} />
              <Typography
                variant="bodySmall"
                textAlign="left"
                style={{ width: "80%" }}
              >
                Unlimited messaging
              </Typography>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <ReadyButton onClick={onReadyButtonClick} />
    </FlexBox>
  )
}

export default CareMarketingPage
