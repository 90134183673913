import {
  FlexBox,
  JoonUIColor,
  SPACING,
  SpacingPxValue,
  SpacingRemValue,
  Typography,
} from "@joonapp/web-shared"

import { ProgramStepIconProps } from "./ProgramStepIcon"

const ListCard = ({
  icon: Icon,
  text,
  iconColor,
  backgroundColor,
  step,
  gap,
}: {
  icon: React.FC<ProgramStepIconProps>
  text: string
  iconColor?: string
  backgroundColor?: string
  step?: number
  gap?: SpacingPxValue | SpacingRemValue | undefined
}) => {
  return (
    <FlexBox
      direction="row"
      align="center"
      justify="left"
      gap={gap}
      wrap={false}
      style={{
        borderRadius: SPACING.space2,
        padding: SPACING.space3,
        marginBottom: "6px",
        backgroundColor,
      }}
    >
      <Icon color={iconColor} size={20} step={step} />
      <Typography
        variant="bodySmall"
        color={JoonUIColor.text.primary}
        textAlign="left"
      >
        {text}
      </Typography>
    </FlexBox>
  )
}

export default ListCard
