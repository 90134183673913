import { Button, FlexBox, SPACING, Typography } from "@joonapp/web-shared"
import * as Sentry from "@sentry/react"
import { ReactNode } from "react"

const ErrorBoundary = ({ children }: { children: ReactNode }) => {
  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      {/* ------- Uncomment these to test error handling------- */}
      {/* <ErrorTrigger /> */}
      {/* <RenderErrorComponent /> */}
      {children}
    </Sentry.ErrorBoundary>
  )
}

const ErrorFallback = ({ error }: { error: Error }) => {
  if (import.meta.env.DEV) {
    return (
      <FlexBox
        align="center"
        justify="center"
        direction="column"
        style={{
          height: "100vh",
          width: "min(100vw, 800px)",
          margin: "0 auto",
        }}
        gap={SPACING.space4}
      >
        <Typography variant="h3" textAlign="center">
          {error.message}
        </Typography>
        <Typography variant="body" textAlign="center">
          {JSON.stringify(error)}
        </Typography>
        <Typography variant="bodyXSmall" textAlign="center">
          {JSON.stringify(error.stack, null, 2)}
        </Typography>
      </FlexBox>
    )
  }
  return (
    <FlexBox
      align="center"
      justify="center"
      direction="column"
      style={{ height: "100vh", width: "100vw" }}
      gap={SPACING.space4}
    >
      <img
        src="/images/avatars/squelchee_thinking.png"
        alt="Sad Squelchee"
        style={{ width: "min(80vw, 250px)" }}
      />
      <FlexBox direction="column">
        <Typography variant="h3" textAlign="center">
          Uh oh, something went wrong.
        </Typography>
        <Typography variant="body" textAlign="center">
          Our team has been notified.
        </Typography>
      </FlexBox>
      <Button text="Reload" onClick={() => window.location.reload()} />
    </FlexBox>
  )
}

export default ErrorBoundary

// ------- Uncomment these to test error handling-------
// const ErrorTrigger: React.FC = () => {
//   const [shouldThrow, setShouldThrow] = useState(false)

//   useEffect(() => {
//     if (shouldThrow) {
//       throw new Error("Triggered error")
//     }
//   }, [shouldThrow])

//   return <button onClick={() => setShouldThrow(true)}>Trigger Error</button>
// }

// const RenderErrorComponent: React.FC = () => {
//   const badFunction = null
//   // @ts-ignore
//   return <div>{badFunction()}</div> // This will cause a TypeError
// }
