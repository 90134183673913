import { FlexBox, SPACING } from "@joonapp/web-shared"
import { useEffect } from "react"

import BasedOnResponsesPage from "./BasedOnResponsesPage"
import CalendlyPage from "./CalendlyPage"
import JoinParentsTextBox from "./JoinParentsTextBox"
import PhoneNumberPage from "./PhoneNumberPage"
import ProgramDetailsPage from "./ProgramDetailsPage"
import ScheduledPage from "./ScheduledPage"
import TransitionWrapper from "./TransitionWrapper"
import { useCoachingStore } from "./useCoachingStore"
import BackButton from "../../components/buttons/BackButton"
import { CoachingStep } from "../../constants"
import useMobile from "../../hooks/useMobile"
import { useRouter } from "../../util/router"

const CoachingPage = () => {
  const { step, setStep } = useCoachingStore()
  const isMobile = useMobile()
  const router = useRouter()

  useEffect(() => {
    if (router.query.from === "academy") {
      setStep(CoachingStep.CALENDLY)
    }
  }, [router.query.from, setStep])

  return (
    <FlexBox
      direction="row"
      justify="center"
      wrap={false}
      style={{
        flexGrow: 1,
        overflowX: "hidden",
        overflowY: "auto",
        minHeight: "100dvh",
        maxHeight: "100dvh",
        textAlign: "center",
      }}
    >
      <FlexBox
        direction="column"
        align="center"
        justify="space-between"
        gap={SPACING.space2}
        wrap={false}
        style={{
          position: "relative",
          width: "min(450px, 100%)",
          height: "calc(100dvh - 20px)",
          ...(isMobile
            ? { margin: "20px 20px 0px", paddingTop: "20px" }
            : { margin: "30px 0px 0px", paddingTop: "0px" }),
        }}
      >
        <div>
          <div
            style={{
              ...(step > 0 && step !== CoachingStep.SCHEDULED
                ? { opacity: 1, pointerEvents: "auto" }
                : { opacity: 0, pointerEvents: "none" }),
              transition: "opacity .2s ease",
              position: "absolute",
              top: "0px",
              left: "0px",
            }}
          >
            {router.query.from !== "academy" && (
              <BackButton onBack={() => setStep(step - 1)} />
            )}
          </div>
          <img
            src={"/images/logos/joon-health-logo.svg"}
            alt=""
            style={{ height: "24px", width: "auto" }}
          />
          <TransitionWrapper
            showChildren={step === CoachingStep.BASED_ON_RESPONSES}
          >
            <BasedOnResponsesPage />
          </TransitionWrapper>
          <TransitionWrapper
            showChildren={step === CoachingStep.PROGRAM_DETAILS}
          >
            <ProgramDetailsPage />
          </TransitionWrapper>
          <TransitionWrapper showChildren={step === CoachingStep.PHONE_NUMBER}>
            <PhoneNumberPage />
          </TransitionWrapper>
          <TransitionWrapper showChildren={step === CoachingStep.CALENDLY}>
            <CalendlyPage />
          </TransitionWrapper>
          <TransitionWrapper showChildren={step === CoachingStep.SCHEDULED}>
            <ScheduledPage />
          </TransitionWrapper>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            ...(step < CoachingStep.PHONE_NUMBER
              ? { opacity: 1, pointerEvents: "auto" }
              : { opacity: 0, pointerEvents: "none" }),
            transition: "opacity .2s ease",
          }}
        >
          <JoinParentsTextBox />
        </div>
      </FlexBox>
    </FlexBox>
  )
}

export default CoachingPage
