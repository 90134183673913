import { User, UserRole } from "@joonapp/web-shared"

export * from "./user"

export interface ParentDataInterface {
  name: string
  nickname: string
  avatar: string | null
  parentQuestionnaireMapping: QuestionAnswerMappingInterface
  childrenData: ChildDataInterface[]
}

export interface QuestionAnswerMappingInterface {
  [key: number]: number[]
}

export interface ChildDataInterface {
  birthdate: number | null
  first_name: string
  questionAnswerMapping: QuestionAnswerMappingInterface
}

export interface AddChildRequest {
  name: string
  gender: string | null
  birthdate: string | null
}

export interface EditChildRequest extends AddChildRequest {
  id: number
}

export interface AnswerInterface {
  id: number
  answer: string
  value: number
}

export interface QuestionInterface {
  id: number
  question: string
  answers: AnswerInterface[]
  type: "single_choice" | "multi_choice"
}

export interface QuestionnairesInterface {
  day_of_week: string | null
  id: number
  onboarding: boolean
  questions: QuestionInterface[]
  title:
    | "child_onboarding_basic"
    | "child_repeating_behavioral"
    | "parent_onboarding"
  type: UserRole.CHILD | UserRole.PARENT
}

export interface AlertInterface {
  message: string
  type: "success" | "error"
}

export interface SubscriberAPIResponse {
  activePlan: {
    planType: string
    isActive: boolean
  }
  planIsActive: boolean
  hasPurchasedPlanBefore: boolean
  request_date: string
  request_date_ms: number
  subscriber: Subscriber
}

export interface Subscriber {
  entitlements: {
    premium: {
      expires_date: string
      grace_period_expires_date?: string
      product_identifier: string
      purchase_date: string
    }
  }
  first_seen: string
  last_seen: string
  management_url?: string | null
  non_subscriptions?: any
  original_app_user_id: string
  original_application_version: string
  original_purchase_date: string
  other_purchases: any
  subscriptions: {
    [key: string]: any
  }
}

export interface CustomErrorInterface extends Error {
  code?: string
}

export interface MessageInterface {
  name: string
  email: string
  message: string
}

declare global {
  interface Window {
    dataLayer: any[]
  }
}

export interface Invitation {
  id: number
  invitee_email: string
  inviter: {
    id: number
    name: string
    email: string
    last_name: string | null
  }
  subject_user: {
    id: number
    name: string
    last_name: string | null
  }
  can_send_reminder: boolean
  type: string
}

export type InvitationGroup = Invitation[]

export enum QuestBoardTab {
  ASSIGNED,
  REVIEW,
}

export enum IncidentLogDataType {
  DURATION = "duration",
  ACCURACY = "accuracy",
  FREQUENCY = "frequency",
  ANTECEDENT = "antecedent",
}

export type IncidentTemplate = {
  target_behaviors: {
    id: number
    incident_log_data_type: IncidentLogDataType
    preventive_strategies: {
      id: number
      title: string
    }
    title: string
  }
  user_id: number
}

export type DataTaskIncidentTemplate = {
  id: number
  title: string
  description: string
  data_type: IncidentLogDataType
  training_id: number
  training: {
    id: number
    title: string
  }
  child: User
  assigner: User
}

export enum FamilyQuerySelect {
  CHILDREN,
  PARENT,
  COPARENTS,
}

export enum ShopItemType {
  ACCESSORY = "accessory",
  DECORATION = "decoration",
  FOOD = "food",
  CONSUMABLE = "consumable",
  NON_CONSUMABLE = "non-consumable",
}

export interface ShopItem {
  id: number
  name: string
  description: string
  type: ShopItemType
  image_url: string
}

export interface InventoryItem {
  id: number
  user_id: number
  shop_item: ShopItem
}

export interface FamilyMail {
  id: number
  body: string
  sender: User
  receiver: User
  sent_datetime: string
  read_datetime: string | null
  shop_item_gift: ShopItem | null
}

export enum FamilyMailboxTab {
  RECEIVED = "Received",
  SENT = "Sent",
}

export interface NotificationPreferences {
  quest_reminder: boolean
  receive_sms: boolean
  verify_quest: boolean
}

export enum SetPhoneNumberSource {
  POST_THERAPIST_LINK = "post_therapist_link",
  SETTINGS = "settings",
}
