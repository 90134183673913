import { DropdownOption, QuestRoutine, QuestStatus } from "@joonapp/web-shared"
import dayjs from "dayjs"

import { SubscriptionTrialKey } from "./types"
import { QuestTemplateCategory } from "./types/quests"
import { displayStringForTemplateCategory } from "./util/choices/quests"

export const SKILL_MAPPING = {
  knowledge: "Learning & Education",
  stamina: "Mental Health",
  strength: "Health & Fitness",
  kindness: "Relationship Building",
  focus: "Focus & Self Control",
  teamwork: "Chores & Housework",
} as { [key: string]: string }

export const SKILL_DROPDOWN = Object.entries(SKILL_MAPPING).map(
  ([key, value]) => ({
    label: value,
    value: key,
  })
)

export const REPETITIONS = {
  daily: [true, true, true, true, true, true, true],
  noDays: [false, false, false, false, false, false, false],
  weekdays: [false, true, true, true, true, true, false],
  weekends: [true, false, false, false, false, false, true],
}

export const validCampaigns = {
  AllspireAtlantic: "AllspireAtlantic",
  AllspireHMH: "AllspireHMH",
  AllspireAtlanticMonthly: "AllspireAtlanticMonthly",
  AllspireHealthPartners: "AllspireHealthPartners",
  AllspireHMHMonthly: "AllspireHMHMonthly",
  AllspireLVHN: "AllspireLVHN",
  AllspireLVHNMonthly: "AllspireLVHNMonthly",
  AllspireMonthly: "AllspireMonthly",
  AmericanSPCC: "AmericanSPCC",
  GoforGrowthLearning: "GoforGrowthLearning",
  ChildFamilyCollective: "ChildFamilyCollective",
  ChildTherapyCollective: "ChildTherapyCollective",
}
export const validClinicianAccess = ["Allspire"]

export const priceIds = {
  default: "1LRiAHG6yUiW8043aCYR1VjP",
  discount: "1MCm5DG6yUiW8043IZIiU1yr",
  discountMonthly: "1PdJ6FG6yUiW8043dpiSbRn6",
  comp: "1NfS3BG6yUiW8043iMx0M8N4",
  test: "1LSN9MG6yUiW8043MGPQMj7c",
  dollarTrial: "1O9spFG6yUiW8043MiuY1Wlo",
  testDollarTrial: "1O9yNIG6yUiW80439nV3VujN",
}

export const PLANS = {
  discount_seven: {
    priceId: priceIds.discount,
    trialLengthKey: SubscriptionTrialKey.SEVEN,
  },
  discount_fourteen: {
    priceId: priceIds.discount,
    trialLengthKey: SubscriptionTrialKey.FOURTEEN,
  },
  discount_seven_monthly: {
    priceId: priceIds.discountMonthly,
    trialLengthKey: SubscriptionTrialKey.SEVEN,
  },
}

export const CAMPAIGNS = {
  [validCampaigns.AmericanSPCC]: PLANS.discount_seven,
  [validCampaigns.GoforGrowthLearning]: PLANS.discount_seven,
  [validCampaigns.ChildFamilyCollective]: PLANS.discount_seven,
  [validCampaigns.ChildTherapyCollective]: PLANS.discount_seven,
  [validCampaigns.AllspireAtlantic]: PLANS.discount_fourteen,
  [validCampaigns.AllspireHealthPartners]: PLANS.discount_seven,
  [validCampaigns.AllspireHMH]: PLANS.discount_fourteen,
  [validCampaigns.AllspireLVHN]: PLANS.discount_seven,
  [validCampaigns.AllspireLVHNMonthly]: PLANS.discount_seven_monthly,
  [validCampaigns.AllspireMonthly]: PLANS.discount_seven_monthly,
  [validCampaigns.AllspireAtlanticMonthly]: PLANS.discount_seven_monthly,
  [validCampaigns.AllspireHMHMonthly]: PLANS.discount_seven_monthly,
}

export const difficultyRewards = {
  easy: 4,
  medium: 10,
  hard: 20,
}

export const ageGroups = {
  six_under: {
    value: "six_under",
    label: "6 & Under",
  },
  seven_nine: {
    value: "seven_nine",
    label: "7-9",
  },
  ten_twelve: {
    value: "ten_twelve",
    label: "10-12",
  },
  thirteen_up: {
    value: "thirteen_up",
    label: "13 & Over",
  },
}

export const PREFERENCE_KEY = {
  hidesFutureRoutines: "hides_future_routines",
  morningLimit: "morning_limit",
  afternoonLimit: "afternoon_limit",
  nightLimit: "night_limit",
  afternoonStartTime: "afternoon_start_time",
  nightStartTime: "night_start_time",
} as const

export enum QuestRepeatFrequency {
  NO_REPEAT = "Does not repeat",
  REPEAT = "On day(s)",
}

export const questRepeatSegmentedControlOptions = [
  {
    label: QuestRepeatFrequency.NO_REPEAT,
    value: QuestRepeatFrequency.NO_REPEAT,
  },
  {
    label: QuestRepeatFrequency.REPEAT,
    value: QuestRepeatFrequency.REPEAT,
  },
]

export const periodDropdownOptions = [
  {
    label: "All Time",
    value: "all_time",
    endDate: dayjs().format("YYYY-MM-DD"),
    startDate: null,
  },
  {
    label: "This Week",
    value: "this_week",
    endDate: dayjs().format("YYYY-MM-DD"),
    startDate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
  },
  {
    label: "This Month",
    value: "this_month",
    endDate: dayjs().format("YYYY-MM-DD"),
    startDate: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
  },
  {
    label: "Custom",
    value: "custom",
    endDate: dayjs().format("YYYY-MM-DD"),
    startDate: null,
  },
]
export type PeriodDropdownOption = (typeof periodDropdownOptions)[number]

export const templateCategoryDropdownOptions = Object.values(
  QuestTemplateCategory
).map((category) => ({
  value: category,
  label: displayStringForTemplateCategory(category),
}))

export const completeQuestStatuses = [
  QuestStatus.COMPLETED,
  QuestStatus.REDEEMED,
  QuestStatus.VERIFIED,
]

export enum QUERY_KEYS {
  QUEST_BOARD = "quest-board",
  QUEST_BOARD_REORDER = "quest-board-reorder",
  QUEST_INSTANCE_STATS = "quest-instance-stats",
  QUEST_REVIEW = "quest-review",
  QUEST_LOG = "quest-log",
  QUESTIONNAIRES = "questionnaires",
  RECOMMENDATION_QUESTIONNAIRES = "recommendation-questionnaires",
  USER = "user",
  FAMILY = "family",
  FAMILY_MAIL = "family-mails",
  UNREAD_MAIL = "family-unread-mails",
  INVENTORY = "inventory-items",
  SUBSCRIBER_INFO = "subscriber-info",
  CHILD_AUTH_CODE = "child-auth-code",
  PARENT_AUTH_CODE = "parent-auth-code",
  CUSTOM_REWARDS = "custom-rewards",
  REWARD_TEMPLATES = "reward-templates",
  PURCHASED_REWARDS = "purchased-rewards",
  QUEST_TEMPLATES = "quest-templates",
  NOTIFICATION_PREFERENCES = "notification-preferences",
  THERAPISTS = "therapists",
  THERAPIST_INVITES = "therapist-invites",
  EXPERIMENTS = "experiments",
  BONUS_REWARDS = "bonus-rewards",
  COIN_TRANSACTIONS = "coin-transactions",
  COIN_CUMULATIVE_STATS = "coin-cumulative-stats",
  NETWORK_INFO = "network-info",
  PARENT_TASKS = "parent-tasks",
  PARENT_TASK_INSTANCES = "parent-task-instances",
  INCIDENT_TEMPLATES = "incident-templates",
  TRAININGS = "trainings",
}

export const questRoutineOptions: DropdownOption[] = [
  {
    label: "All",
    value: null,
  },
  {
    label: "Morning",
    value: QuestRoutine.MORNING,
  },
  {
    label: "Afternoon",
    value: QuestRoutine.AFTERNOON,
  },
  {
    label: "Night",
    value: QuestRoutine.NIGHT,
  },
  {
    label: "Anytime",
    value: QuestRoutine.ANYTIME,
  },
]

export const GRADIENTS = {
  gradient100: `linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8) 2.83%,
    rgba(255, 255, 255, 0) 51.53%,
    rgba(255, 255, 255, 0.8) 99.81%
  ),
  #e9eafa`,
}

export enum CoachingStep {
  BASED_ON_RESPONSES,
  PROGRAM_DETAILS,
  PHONE_NUMBER,
  CALENDLY,
  SCHEDULED,
}
