import { JoonColorExpanded, ParentTrainingStatus } from "@joonapp/web-shared"

import AcademyPageHeader from "./AcademyPageHeader"
import CareMarketingPage from "./careMarketing/CareMarketingPage"
import Tour from "./tourAcademyTab/Tour"
import TrainingsList from "./TrainingsList"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { GRADIENTS } from "../../constants"
import { useTrainingsQuery, useUserQuery } from "../../networking/queries"
import { requireAuth } from "../../util/auth"
import {
  cohorts,
  experimentNames,
  useGetExperimentCohort,
} from "../../util/experiments"

const AcademyPage = requireAuth(() => {
  const { user } = useUserQuery()
  const { isLoading: isLoadingIP } = useTrainingsQuery(
    ParentTrainingStatus.IN_PROGRESS
  )
  const { isLoading: isLoadingMastered } = useTrainingsQuery(
    ParentTrainingStatus.MASTERED
  )
  const variant = useGetExperimentCohort(
    experimentNames.webParentVirtualCoachOnboarding
  )

  const isExperiment = !user?.show_academy_tab && variant !== cohorts.control

  if (variant === null) return <></>

  return (
    <PageWrapper
      style={{
        ...(isExperiment
          ? { background: JoonColorExpanded.yellow100, paddingBottom: "0px" }
          : { background: GRADIENTS.gradient100, paddingBottom: "200px" }),
      }}
    >
      {isExperiment ? (
        <CareMarketingPage />
      ) : (
        <>
          <AcademyPageHeader />
          <TrainingsList />
          {!isLoadingIP && !isLoadingMastered ? <Tour /> : <></>}
        </>
      )}
    </PageWrapper>
  )
})

const AcademyPageWithAuth = requireAuth(AcademyPage)
export default AcademyPageWithAuth
