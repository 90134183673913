import {
  Identify,
  identify,
  init,
  reset,
  track,
  setGroup,
  setUserId,
} from "@amplitude/analytics-browser"

import { localStorageItems } from "./storage"

export const ANALYTIC_EVENTS = {
  // Auth
  PARENT_LOGIN_APPROVED: "parent_login_approved",
  GOOGLE_LOGIN_APPROVED: "google_login_approved",
  APPLE_LOGIN_APPROVED: "apple_login_approved",

  AUTH_NO_ACCOUNT: "auth_no_account",
  AUTH_HAS_ACCOUNT: "auth_has_account",
  CREATE_LOGIN: "create_login",
  PURCHASE_COMPLETED: "purchase_completed",
  PURCHASE_CANCELLED: "purchase_cancelled",
  SIGNUP_FAILED: "signup_failed",

  // Invited by therapist/code page
  INVITED_BY_THERAPIST: "invited_by_therapist",
  NOT_INVITED_BY_THERAPIST: "not_invited_by_therapist",
  ONBOARDING_USING_THERAPIST_CODE: "onboarding_using_therapist_code",

  // Onboarding
  SHOW_WELCOME: "show_welcome",
  START_ONBOARD: "start_onboard",
  START_CHILD_ONBOARDING_SURVEY: "start_child_onboarding_survey",
  INPUT_CHILD_INFO_INTENT: "input_child_info_intent",
  INPUT_CHILD_INFORMATION: "input_child_information",
  ADD_CHILDREN: "add_children",
  SELECT_PARENT_AVATAR: "select_parent_avatar",
  ADD_PARENT_NAME: "add_parent_name",
  ADD_PARENT_NICKNAME: "add_parent_nickname",
  ADD_PARENT_NAME_AND_NICKNAME: "add_parent_name_and_nickname",
  ENTER_COPARENT_CODE: "enter_coparent_code",
  INVALID_COPARENT_CODE: "invalid_coparent_code",
  ONBOARDING_QUESTION: (question: number) => `onboarding_question_${question}`,
  SHOW_CHECKOUT: "show_checkout",
  EXPERIMENT_WEB_WELCOME: "experiment_web_welcome",
  EXPERIMENT_WEB_WELCOME_CTA: "experiment_web_welcome_cta",
  IOS_DOWNLOAD_PROMPT: "ios_download_prompt", // params: { action: 'download' | 'no_iphone' | 'close'}
  IOS_DOWNLOAD_LINK_CLICK: "ios_download_link_click",
  IOS_BANNER_CLICK: "ios_banner_click",
  ADD_TO_HOMESCREEN_BANNER_CLICK: "add_to_homescreen_banner_click",
  HAS_ACCOUNT: "has_account",
  HAS_FAMILY_CODE: "has_family_code",
  CONTINUE_ADD_CHILDREN: "continue_add_children",
  ONBOARD_HAS_ACCOUNT_LOGIN_BUTTON_CLICK:
    "onboard_has_account_login_button_click",
  ONBOARD_HAS_FAMILY_BUTTON_CLICK: "onboard_has_family_button_click",
  GET_STARTED_STEP_CLICKED: "get_started_step_clicked", // params: {step: number step}
  ABA_QUESTION_ANSWERED: "aba_question_answered", // user said their child has ABA

  // Paywall
  SHOW_PAYWALL: "show_paywall",
  PAYWALL_START_TRIAL: "paywall_start_trial",
  PAYWALL_SKIP: "paywall_skip",
  PAYWALL_SELECT_PRICE: "paywall_select_price", // params: { price }
  PAYWALL_NEXT: "paywall_next",

  // Virtual Coach Onboarding
  VIRTUAL_COACH_ONBOARDING_START: "virtual_coach_onboarding_start", // landed on coaching/training page
  VIRTUAL_COACH_CONTINUE_CLICK: "virtual_coach_continue_click", // any time next step is clicked, params: { step: number }
  VIRTUAL_COACH_SKIP_CLICK: "virtual_coach_skip_click", // any time skip to "/me" is clicked, params: { step: number }
  VIRTUAL_COACH_PHONE_NUMBER_SUBMIT: "virtual_coach_phone_number_submit", // on submit of phone number
  CALENDLY_DATE_TIME_SELECTED: "calendly_date_time_selected", // when date and time of new calendly event is selected
  CALENDLY_EVENT_SCHEDULED: "calendly_event_scheduled", // when calendly event is scheduled
  VIRTUAL_COACH_ONBOARDING_COMPLETE: "virtual_coach_onboarding_complete", // when "Let's Go!" on last step is clicked

  // Coach/Care Marketing
  MARKETING_TAB_VIEWED: "marketing_tab_viewed", // user views marketing tab
  MARKETING_BUTTON_CLICK: "marketing_button_click", // user clicks "Ready to try it out" in marketing tab
  MARKETING_DATE_TIME_SELECTED: "marketing_date_time_selected", // when date and time of new calendly event is selected
  MARKETING_EVENT_SCHEDULED: "marketing_event_scheduled", // when calendly event is scheduled
  MARKETING_NEVERMIND_CLICK: "marketing_nevermind_click", // user clicks nevermind in marketing tab

  // Quests
  ADD_QUEST: "add_quest",
  COMPLETE_QUEST: "qb_complete_quest",
  CREATE_CUSTOM_QUEST: "qb_create_custom_quest", // params: { source: location of the custom quest creation }
  SKIP_QUEST: "qb_skip_quest",
  OPEN_EDIT_QUEST: "qb_open_edit_quest",
  EDIT_QUEST: "qb_edit_quest",
  VIEW_QUEST_DETAILS: "qb_view_quest_details",
  DELETE_QUEST: "qb_delete_quest",
  CHANGE_QB_DATE: "qb_change_date",
  VIEW_ASSIGNED_TAB: "qb_view_assigned_tab",
  VIEW_VERIFY_TAB: "qb_view_verify_tab",
  VERIFY_QUEST: "verify_quest",
  VERIFY_ALL_QUESTS: "qb_verify_all_quests",
  UNDO_QUEST_VERIFY: "qb_undo_quest_verify",
  ADD_QUEST_INTENT: "add_quest_intent", // user opens add quest modal
  TEMPLATE_CATEGORY_CLICK: "qb_template_category_click", // user clicks a template category, { category: string }
  TEMPLATE_CLICK: "qb_template_click", // user clicks a template,
  RETRY_QUEST: "retry_quest",
  REJECT_QUEST: "reject_quest",
  COMPLETE_MULTIPLE_QUESTS: "complete_multiple_quests",
  SKIP_MULTIPLE_QUESTS: "skip_multiple_quests",
  REJECT_MULTIPLE_QUESTS: "reject_multiple_quests",

  // Rewards
  ADD_REWARD: "rewards_add_reward", // user adds a reward
  EDIT_REWARD: "rewards_edit_reward", // user edits a reward
  DELETE_REWARD: "rewards_delete_reward", // user deletes a reward
  SEND_BONUS_REWARD: "send_bonus_reward", // user opens bonus reward modal
  CUSTOM_REWARD_CREATE_CUSTOM: "custom_reward_create_custom", // user opens add reward modal
  BONUS_REWARD_SENT: "rewards_bonus_reward_sent", // user sends bonus reward

  // Family
  SEND_THERAPIST_INVITE: "send_therapist_invite",
  MANAGE_THERAPISTS_BUTTON_CLICK: "manage_therapists_button_click",
  FAMILY_TAB_MANAGE_FAMILY: "family_tab_manage_family",
  FAMILY_TAB_MANAGE_THERAPISTS: "family_tab_manage_therapists",
  ADD_THERAPIST_PROMPT_INVITE: "add_therapist_prompt_invite",

  // Family mailbox
  OPEN_FAMILY_MAILBOX: "open_family_mailbox",
  VIEW_FAMILY_MAIL: "view_family_mail",
  COMPOSE_FAMILY_MAIL: "compose_family_mail",
  SEND_FAMILY_MAIL: "send_family_mail",

  // OTHER:
  NAV_ITEM_CLICK: "nav_item_click", // params: page
  CONTACT_US_CLICK: "contact_us_click", // user clicks contact us button
  AUTH_CODE_COPY_CLICK: "auth_code_copy_click", // user clicks copy auth code. params: location, type
  EMAIL_CAMPAIGN_CLICKED: "email_campaign_clicked", // user clicks a link in an email campaign
  SET_PHONE_NUMBER: "set_phone_number", // source param is either: post_therapist_link or settings
  OPEN_TEXT_MESSAGE_SETTINGS: "open_text_message_settings",

  // Browser navigation
  PAGE_REFRESH: "page_refresh", // User refreshes page
  PAGE_CLOSE: "page_close", // User closes page
  PAGE_BACK_FORWARD: "page_back_forward", // User navigates away using back or forward button

  // Me
  OPEN_QUESTS_REVIEW_CATCHUP: "open_quests_review_catchup",
  OPEN_QUESTS_COMPLETE_CATCHUP: "open_quests_complete_catchup",
  CLICK_TODAYS_PROGRESS: "click_todays_progress",
  SHOW_ABA_THERAPIST_PROMPT: "show_aba_therapist_prompt",
  ABA_THERAPIST_FORCE_CLOSE: "aba_therapist_force_close",
  SHOW_CATCHUP_END_PARENT_TASKS_REMINDER:
    "show_catchup_end_parent_tasks_reminder",
  CATCHUP_END_PARENT_TASKS_REMINDER_CLOSE:
    "catchup_end_parent_tasks_reminder_close",
  INVITE_THERAPIST_EMAIL: "invite_therapist_email",

  // Parent tasks
  OPEN_PARENT_TASK_RESOURCE: "open_parent_task_resource",
  PARENT_TASKS_INITIATE_COMPLETE: "parent_tasks_initiate_complete",
  PARENT_TASKS_FINISH_COMPLETE: "parent_tasks_finish_complete",
  OPEN_PARENT_TASKS_SHEET: "open_parent_tasks_sheet",

  // Data tasks
  OPEN_DATA_COLLECTION_TASKS: "open_data_collection_tasks",
  INITIATE_DATA_COLLECTION_TASK: "initiate_data_collection_task",
  SUBMIT_DATA_COLLECTION_TASK: "submit_data_collection_task",

  // Incidents
  INITIATE_LOG_INCIDENT: "initiate_log_incident",
  ENTER_LOG_INCIDENT_CHILD_SELECTION: "enter_log_incident_child_selection",
  ENTER_LOG_INCIDENT_BEHAVIOR_DESCRIPTION:
    "enter_log_incident_behavior_description",
  ENTER_LOG_INCIDENT_STRATEGY_DESCRIPTION:
    "enter_log_incident_strategy_description",
  SUBMIT_INCIDENT_LOG: "submit_incident_log",
  LOG_ANOTHER_INCIDENT: "log_another_incident",

  // Parent Training
  OPEN_PARENT_TRAINING_DETAILS: "open_parent_training_details",
  START_CONTINUE_LEARNING_MODULES: "start_continue_learning_modules",
  OPEN_LEARNING_MODULE: "open_learning_module",
  COMPLETE_BASIC_LEARNING_ASSIGNMENT: "complete_basic_learning_assignment", // when the user completes PDF/readingtext/video
  COMPLETE_SHORT_ANSWER_LEARNING_ASSIGNMENT:
    "complete_short_answer_learning_assignment", // when the user submits ALL short answer questions
  COMPLETE_MULTIPLE_CHOICE_LEARNING_ASSIGNMENT:
    "complete_multiple_choice_learning_assignment", // when the user submits ALL multiple choice questions
  FINISH_CURRENT_LEARNING_MODULES: "finish_current_learning_modules", // when the user gets to the end of the learning modules and we're dismissing the screen
}

export enum FacebookPixelEvent {
  CONTACT = "Contact",
  COMPLETE_REGISTRATION = "CompleteRegistration",
  START_TRIAL = "StartTrial",
  PURCHASE_SUBSCRIPTION = "Purchase",
}

export enum TiktokPixelEvent {
  START_TRIAL = "PlaceAnOrder",
  PURCHASE_SUBSCRIPTION = "CompletePayment",
  COMPLETE_REGISTRATION = "CompleteRegistration",
  CONTACT = "Contact",
}

const isProduction = import.meta.env.MODE === "production"
// Initialize amplitude
isProduction &&
  init(import.meta.env.VITE_AMPLITUDE_API_KEY as string, undefined, {
    minIdLength: 1,
  })
// Initialize hotjar
// isProduction && Hotjar.init(3623886, 6)

export function analyticsUserDidLogin(
  parentId: number,
  familyId: number,
  email: string
) {
  // User id (as string)
  setUserId(`${parentId}`)

  // User properties
  const event = new Identify()
  event.set("family_id", `${familyId}`)
  event.set("email", email)
  localStorage.setItem(localStorageItems.analyticsFamilyId, `${familyId}`)
  localStorage.setItem(localStorageItems.analyticsUserId, `${parentId}`)
  identify(event)

  // Set family group
  setGroup("family", `${familyId}`)
}

export function analyticsUserDidLogout() {
  reset()
  localStorage.removeItem(localStorageItems.analyticsFamilyId)
  localStorage.removeItem(localStorageItems.analyticsUserId)
}

export function trackAnalyticEvent(
  eventName: string,
  eventParams: { [key: string]: any } = {}
) {
  if (!isProduction) return
  eventParams.platform = "web"
  eventParams.family_id = localStorage.getItem(
    localStorageItems.analyticsFamilyId
  )
  eventParams.user_id = localStorage.getItem(localStorageItems.analyticsUserId)

  track(eventName, eventParams)
}

export enum GAEvent {
  START_QUIZ = "AW-343454768/kDV8CILi5dQDELDo4qMB",
  CREATE_ACCOUNT = "AW-343454768/QAqSCPrN5dQDELDo4qMB",
  START_TRIAL = "AW-343454768/-_y7CLiBtqYYELDo4qMB",
}
export function trackGAEvent(gaEvent: string, data?: any) {
  if (import.meta.env.DEV) return
  gtag("event", "conversion", { send_to: gaEvent, ...data })
}
