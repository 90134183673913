import { FlexBox, JoonUIColor, SPACING } from "@joonapp/web-shared"
import { useState } from "react"
import { Link, useLocation } from "react-router-dom"

import NotificationDot from "../../pages/family/NotificationDot"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

type Props = {
  to: string
  name: string
  Icon: any
}

const NavLink = ({ to, name, Icon }: Props) => {
  const [isHovered, setIsHovered] = useState(false)
  const location = useLocation()

  const isOnDefaultPage = location.pathname === "/" && to === "/me"
  const isActive =
    "/" + location.pathname.split("/")[1] === to || isOnDefaultPage

  return (
    <Link
      to={to}
      onClick={() =>
        trackAnalyticEvent(ANALYTIC_EVENTS.NAV_ITEM_CLICK, { page: name })
      }
      style={{ width: "100%" }}
    >
      <div className={to === "/academy" ? "tour-step-1" : ""}>
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            fontSize: "16px",
            padding: `${SPACING.space4} ${SPACING.space4}`,
            alignItems: " center",
            display: "flex",
            whiteSpace: "nowrap",
            textDecoration: "none !important",
            margin: `0 ${SPACING.space2}`,
            borderRadius: "16px",
            color: isActive
              ? JoonUIColor.text.inverted
              : JoonUIColor.text.primary,
            fontWeight: isActive ? "600" : "500",
            background: isActive
              ? `radial-gradient(
                155.78% 153.07% at 101.11% -5.68%,
                rgba(255, 255, 255, 0.4) 0%,
                rgba(255, 255, 255, 0) 100%
              ),
              radial-gradient(
                231% 135.8% at 0.9% 2.98%,
                rgba(255, 255, 255, 0.16) 0%,
                rgba(255, 255, 255, 0) 100%
              ), ${JoonUIColor.semantic.primary}`
              : isHovered
                ? JoonUIColor.background.lightAccent
                : "inherit",
          }}
        >
          <FlexBox
            align="center"
            justify="center"
            style={{
              position: "relative",
              width: "fit-content",
              height: "fit-content",
              marginRight: SPACING.space4,
            }}
          >
            <Icon
              color={
                isActive
                  ? JoonUIColor.text.inverted
                  : JoonUIColor.text.secondary
              }
              size={18}
            />
            {to === "/family" && <NotificationDot size="7px" />}
          </FlexBox>
          {name}
        </div>
      </div>
    </Link>
  )
}

export default NavLink
