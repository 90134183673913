import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"

import useMediaQuery from "../../hooks/useMediaQuery"
import useMobile from "../../hooks/useMobile"

const JoinParentsTextBox = () => {
  const isMobile = useMobile()
  const isSmallScreen = useMediaQuery("(max-height:800px)")
  return (
    <FlexBox
      direction="row"
      align="center"
      gap={SPACING.space2}
      wrap={false}
      style={{
        bottom: isMobile || isSmallScreen ? 0 : "100px",
        background: JoonUIColor.background.lightAccent,
        borderRadius: isMobile ? "20px 20px 0 0" : "20px",
        padding: `${SPACING.space2} ${SPACING.space3}`,
        boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
        textAlign: "left",
        zIndex: 10,
        ...(isMobile
          ? {
              position: "relative",
              left: "50%",
              right: "50",
              marginLeft: "-50vw",
              marginRight: "-50vw",
              width: "100vw",
            }
          : { position: "fixed", width: "min(450px, 100%)" }),
      }}
    >
      <img
        src={"/images/avatars/parents.svg"}
        alt=""
        style={{ height: "32px", width: "auto" }}
      />
      <Typography variant="bodyXSmall" color={JoonUIColor.text.secondary}>
        Join thousands improving their child's behavior through the help of
        parent coaching.
      </Typography>
    </FlexBox>
  )
}

export default JoinParentsTextBox
