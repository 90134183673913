import {
  CalendarSolidIcon,
  GiftIcon,
  JoonUIColor,
  ParentTrainingIcon,
  SPACING,
  UserIcon,
  UsersIcon,
} from "@joonapp/web-shared"

import MobileNavLink from "./MobileNavLink"
import { useFamilyQuery, useUserQuery } from "../../networking/queries"
import { FamilyQuerySelect } from "../../types"
import {
  cohorts,
  experimentNames,
  useGetExperimentCohort,
} from "../../util/experiments"

const MobileNav = () => {
  const { user } = useUserQuery()
  const { data: children, isLoading: isLoadingChildren } = useFamilyQuery(
    FamilyQuerySelect.CHILDREN
  )

  const notLinkedOrHasOnboardedChildren =
    !user?.show_academy_tab ||
    children?.find((child) => child?.user?.active_doter?.type)

  const variant = useGetExperimentCohort(
    experimentNames.webParentVirtualCoachOnboarding
  )

  const academyTabName =
    user?.show_academy_tab || variant === cohorts.control
      ? "Academy"
      : variant === cohorts.variantA
        ? "Coach"
        : "Care"

  if ((user?.show_academy_tab && isLoadingChildren) || variant === null)
    return <></>

  return (
    <div
      style={{
        background: JoonUIColor.background.primaryNeutral,
        position: "fixed",
        bottom: 0,
        width: "100vw",
        padding: `0 ${SPACING.space4}`,
        height: "70px",
        borderTop: `1px solid ${JoonUIColor.border.default}`,
        zIndex: "500",
        display: "flex",
        justifyContent: "space-around",
        boxShadow: "2px 0px 30px 0px rgba(36, 23, 185, 0.25)",
      }}
    >
      {notLinkedOrHasOnboardedChildren && (
        <MobileNavLink to="/me" name="Me" Icon={UserIcon} />
      )}
      {(user?.show_academy_tab || variant !== cohorts.control) && (
        <MobileNavLink
          to="/academy"
          name={academyTabName}
          Icon={ParentTrainingIcon}
        />
      )}
      <MobileNavLink to="/quests" name="Quests" Icon={CalendarSolidIcon} />
      {notLinkedOrHasOnboardedChildren && (
        <MobileNavLink to="/rewards" name="Rewards" Icon={GiftIcon} />
      )}
      <MobileNavLink to="/family" name="Family" Icon={UsersIcon} />
    </div>
  )
}

export default MobileNav
