import { FlexBox, SPACING } from "@joonapp/web-shared"
import { useQueryClient } from "@tanstack/react-query"
import { useEffect, useState } from "react"

import CatchUpButton from "./CatchUpButton"
import CatchUpCompleteButton from "./CatchUpCompleteButton"
import DownloadAppBanner from "./DownloadAppBanner"
import InviteABASection from "./parentTasks/InviteABASection"
import ParentTasksSection from "./parentTasks/ParentTasksSection"
import QuickActionsSection from "./QuickActionsSection"
import TodaysProgressSection from "./TodaysProgressSection"
import ChildAuthCode from "../../components/childAuthCode/ChildAuthCode"
import PageLoader from "../../components/loading/PageLoader"
import PageContentWrapper from "../../components/pageContentWrapper/PageContentWrapper"
import PageHeaderLeft from "../../components/pageHeader/PageHeaderLeft"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { GRADIENTS, QUERY_KEYS } from "../../constants"
import { useFamilyQuery, useUserQuery } from "../../networking/queries"
import { FamilyQuerySelect } from "../../types"
import { requireAuth } from "../../util/auth"
import { useRouter } from "../../util/router"

const MePage = requireAuth(() => {
  const { user } = useUserQuery()
  const { data: children, isLoading: isLoadingChildren } = useFamilyQuery(
    FamilyQuerySelect.CHILDREN
  )
  const queryClient = useQueryClient()
  const router = useRouter()
  const [isPageLoading, setIsPageLoading] = useState(false)

  useEffect(() => {
    queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
    queryClient.invalidateQueries([QUERY_KEYS.QUEST_REVIEW])
    queryClient.invalidateQueries([QUERY_KEYS.QUEST_INSTANCE_STATS])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setIsPageLoading(true)
    if (isLoadingChildren) return
    const linkedAndHasNoOnboardedChildren =
      user?.show_academy_tab &&
      !children?.find((child) => child?.user?.active_doter?.type)
    if (linkedAndHasNoOnboardedChildren) router.push("/academy")
    setIsPageLoading(false)
  }, [router, isLoadingChildren, user, children])

  if (user?.show_academy_tab && (isLoadingChildren || isPageLoading))
    return <PageLoader />

  return (
    <PageWrapper style={{ background: GRADIENTS.gradient100 }}>
      <DownloadAppBanner />
      <PageContentWrapper mobileGutter>
        <PageHeaderLeft>Welcome back, {user?.name}!</PageHeaderLeft>
        <ChildAuthCode />
        <FlexBox direction="column" gap={SPACING.space6} align="unset">
          <CatchUpButton />
          <CatchUpCompleteButton />
          <QuickActionsSection />
          <ParentTasksSection />
          <InviteABASection />
          <TodaysProgressSection />
        </FlexBox>
      </PageContentWrapper>
    </PageWrapper>
  )
})

export default MePage
