import { create } from "zustand"

import { CoachingStep } from "../../constants"

interface CoachingStore {
  step: CoachingStep
  setStep: (value: CoachingStep) => void
  phoneNumber: string
  setPhoneNumber: (value: string) => void
  phoneNumberInput: string
  setPhoneNumberInput: (value: string) => void
}

export const useCoachingStore = create<CoachingStore>((set) => ({
  step: CoachingStep.BASED_ON_RESPONSES,
  setStep: (step: CoachingStep) => set({ step }),
  phoneNumber: "",
  setPhoneNumber: (phoneNumber: string) => set({ phoneNumber }),
  phoneNumberInput: "",
  setPhoneNumberInput: (phoneNumberInput: string) => set({ phoneNumberInput }),
}))
