import {
  Button,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  ParentTrainingStatus,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import useTraining from "./useTraining"
import useTrainingStore from "./useTrainingStore"
import LinearProgress from "../../../components/linearProgress/LinearProgress"
import PageLoader from "../../../components/loading/PageLoader"
import PageWrapper from "../../../components/pageWrapper/PageWrapper"
import { GRADIENTS } from "../../../constants"
import {
  useIncidentTemplatesQuery,
  useTrainingsQuery,
} from "../../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { requireAuth } from "../../../util/auth"
import { useRouter } from "../../../util/router"
import { BackButton } from "../BackButton"
import DataTaskListItem from "../dataTasks/DataTaskListItem"
import ModuleAssignmentListItem from "../ModuleAssignmentListItem"
import TaskListItem from "../TaskListItem"

const TrainingPage = () => {
  const router = useRouter()
  const { onOpen, setModuleIndex } = useTrainingStore()
  const selectedTrainingId = router.params.id
  const { isLoading } = useTrainingsQuery()
  const { currentTraining, completionPercentage } = useTraining(
    selectedTrainingId ? +selectedTrainingId : undefined
  )
  const { data: incidentTemplates } = useIncidentTemplatesQuery()
  const dataTasks = incidentTemplates?.parent_training_templates
  const hasDataTasks = dataTasks && dataTasks.length > 0

  const trainingIncludesTasks =
    currentTraining && currentTraining?.quests.length > 0
  const trainingIncludesModules =
    currentTraining && currentTraining?.assignments.length > 0

  if (isLoading) return <PageLoader />
  if (!currentTraining) return null

  const displayStartButton =
    currentTraining.status !== ParentTrainingStatus.MASTERED &&
    completionPercentage !== 100 &&
    trainingIncludesModules

  const onClickStartTraining = () => {
    onOpen(currentTraining.id)
    setModuleIndex(0)
    trackAnalyticEvent(ANALYTIC_EVENTS.START_CONTINUE_LEARNING_MODULES)
  }

  return (
    <PageWrapper style={{ background: GRADIENTS.gradient100 }}>
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        style={{
          width: "min(600px, 100%)",
          padding: `${SPACING.space4}`,
          paddingBottom: "200px",
        }}
      >
        <BackButton
          onClick={() => router.push("/academy")}
          text="All lessons"
        />
        <Typography
          variant="h2"
          color={JoonUIColor.text.primary}
          textAlign="center"
          style={{ padding: `${SPACING.space4} 0` }}
        >
          {currentTraining?.title}
        </Typography>
        {trainingIncludesModules && (
          <FlexBox
            direction="column"
            style={{
              border: `1px solid ${JoonUIColor.border.default}`,
              borderRadius: SPACING.space2,
              overflow: "hidden",
              background: JoonUIColor.background.primaryNeutral,
            }}
          >
            <FlexBox
              align="center"
              wrap={false}
              style={{
                padding: `${SPACING.space2} ${SPACING.space4}`,
                borderBottom: `1px solid ${JoonUIColor.border.default}`,
              }}
              justify="space-between"
            >
              <Typography variant="bodyBold">Learning Modules</Typography>
              {trainingIncludesModules && (
                <LinearProgress
                  value={completionPercentage}
                  style={{ width: "40%" }}
                  colorPrimary={JoonColorExpanded.viridian300}
                  displayValue
                />
              )}
            </FlexBox>
            <FlexBox direction="column">
              {currentTraining?.assignments.map((assignment, i) => (
                <ModuleAssignmentListItem
                  assignment={assignment}
                  key={i}
                  index={i}
                  isLastItem={i === currentTraining.assignments.length - 1}
                />
              ))}
            </FlexBox>
          </FlexBox>
        )}
        {hasDataTasks && (
          <FlexBox
            direction="column"
            gap={SPACING.space2}
            style={{
              border: `1px solid ${JoonUIColor.border.default}`,
              borderRadius: SPACING.space2,
              background: JoonUIColor.background.primaryNeutral,
              overflow: "hidden",
            }}
          >
            <FlexBox direction="column" wrap={false}>
              <FlexBox
                align="center"
                wrap={false}
                style={{
                  padding: `${SPACING.space2} ${SPACING.space4}`,
                  borderBottom: `1px solid ${JoonUIColor.border.default}`,
                }}
                justify="space-between"
              >
                <Typography variant="bodyBold">Practice Tasks</Typography>
              </FlexBox>
              <FlexBox direction="column">
                {dataTasks.map((task, i) => (
                  <DataTaskListItem
                    dataTask={task}
                    isLastItem={i === currentTraining.assignments.length - 1}
                  />
                ))}
              </FlexBox>
            </FlexBox>
          </FlexBox>
        )}
        {trainingIncludesTasks && (
          <FlexBox
            direction="column"
            gap={SPACING.space2}
            style={{
              border: `1px solid ${JoonUIColor.border.default}`,
              borderRadius: SPACING.space2,
              background: JoonUIColor.background.primaryNeutral,
              overflow: "hidden",
            }}
          >
            <FlexBox direction="column" wrap={false}>
              <FlexBox
                align="center"
                wrap={false}
                style={{
                  padding: `${SPACING.space2} ${SPACING.space4}`,
                  borderBottom: `1px solid ${JoonUIColor.border.default}`,
                }}
                justify="space-between"
              >
                <Typography variant="bodyBold">Quick To-dos</Typography>
              </FlexBox>
              <FlexBox direction="column">
                {currentTraining?.quests.map((quest, i) => (
                  <TaskListItem
                    questSeries={quest}
                    isLastItem={i === currentTraining.quests.length - 1}
                  />
                ))}
              </FlexBox>
            </FlexBox>
          </FlexBox>
        )}
        {displayStartButton && (
          <Button
            name="Start Training"
            onClick={onClickStartTraining}
            fullWidth
            text={
              completionPercentage === 0
                ? "Start Training"
                : "Continue Training"
            }
            style={{ marginTop: SPACING.space6 }}
          />
        )}
      </FlexBox>
    </PageWrapper>
  )
}

const TrainingPageWithAuth = requireAuth(TrainingPage)
export default TrainingPageWithAuth
