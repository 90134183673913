import {
  ChevronRightIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  ParentTraining,
  SPACING,
  Typography,
  PersonWalkingIcon,
  ParentTrainingStatus,
  FlagIcon,
} from "@joonapp/web-shared"
import { useEffect } from "react"

import useTourStore from "./tourAcademyTab/useTourStore"
import useTraining from "./training/useTraining"
import LinearProgress from "../../components/linearProgress/LinearProgress"
import { TrainingSkeleton } from "../../components/skeleton/Skeleton"
import { useTrainingsQuery } from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"

const TrainingsList = () => {
  const { data: trainingsIP, isLoading: isLoadingIP } = useTrainingsQuery(
    ParentTrainingStatus.IN_PROGRESS
  )
  const { data: trainingsMastered, isLoading: isLoadingMastered } =
    useTrainingsQuery(ParentTrainingStatus.MASTERED)

  const hasMasteredTrainings = trainingsMastered && trainingsMastered.length > 0

  return (
    <FlexBox
      direction="column"
      wrap={false}
      style={{ padding: SPACING.space4, width: "min(600px, 100%)" }}
      gap={SPACING.space2}
    >
      <FlexBox direction="row" gap={SPACING.space2} align="center">
        <PersonWalkingIcon size={16} color={JoonUIColor.text.primary} />
        <Typography variant="bodyBold">Continue lessons</Typography>
      </FlexBox>
      <FlexBox direction="column" gap={SPACING.space2}>
        {isLoadingIP || isLoadingMastered ? (
          <TrainingSkeleton />
        ) : (
          trainingsIP?.map((training) => (
            <TrainingListItem training={training} />
          ))
        )}
      </FlexBox>
      {hasMasteredTrainings && (
        <>
          <div style={{ marginTop: SPACING.space2 }}>
            <FlexBox direction="row" gap={SPACING.space2} align="center">
              <FlagIcon size={16} color={JoonUIColor.text.primary} />
              <Typography variant="bodyBold">Completed lessons</Typography>
            </FlexBox>
            <Typography variant="body">
              The following lessons have been marked as
              <strong> Mastered </strong>
              by your clinician.
            </Typography>
          </div>
          <FlexBox direction="column" gap={SPACING.space2}>
            {trainingsMastered?.map((training) => (
              <TrainingListItem training={training} />
            ))}
          </FlexBox>
        </>
      )}
    </FlexBox>
  )
}

export default TrainingsList

const TrainingListItem = ({ training }: { training: ParentTraining }) => {
  const { completionPercentage } = useTraining(training.id)

  const router = useRouter()

  const { trainingId, setTrainingId } = useTourStore()
  const isInitialTraining = training.title === "Getting Started with Joon"

  const onClickTrainingItem = () => {
    router.push(`/academy/${training.id}`)
    trackAnalyticEvent(ANALYTIC_EVENTS.OPEN_PARENT_TRAINING_DETAILS)
  }

  useEffect(() => {
    if (isInitialTraining && trainingId === null) {
      setTrainingId(training.id)
    }
  }, [trainingId, setTrainingId, training.id, isInitialTraining])

  return (
    <button
      className={isInitialTraining ? "tour-step-2" : ""}
      style={{
        padding: SPACING.space4,
        background: JoonUIColor.background.primaryNeutral,
        width: "100%",
        borderRadius: SPACING.space2,
      }}
      onClick={onClickTrainingItem}
    >
      <FlexBox direction="row" wrap={false} align="center">
        <FlexBox direction="column" gap={SPACING.space0}>
          <Typography variant="bodyBold">{training.title}</Typography>
          {training.status === ParentTrainingStatus.IN_PROGRESS && (
            <LinearProgress
              value={completionPercentage}
              style={{ width: "40%" }}
              colorPrimary={JoonColorExpanded.viridian300}
              displayValue
            />
          )}
        </FlexBox>
        <FlexBox
          align="center"
          justify="center"
          style={{
            width: "fit-content",
            background: JoonUIColor.background.lightGray,
            borderRadius: "50%",
            padding: SPACING.space1,
          }}
        >
          <ChevronRightIcon size={20} color={JoonUIColor.text.primaryAccent} />
        </FlexBox>
      </FlexBox>
    </button>
  )
}
